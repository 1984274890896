export const GET_USERS = "users/GET_USERS";
export const GET_ALL_USERS = "users/GET_ALL_USERS";
export const FILTER_USER = "users/FILTER_USER";

const initialState = {
  data: [],
  allData: [],
  params: null,
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
};

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex((i) => i.id === arr2[0].id) + 1;
    let endIndex = arr.findIndex((i) => i.id === arr2[arr2.length - 1].id) + 1;
    let finalArr = [startIndex, endIndex];
    return (arr3 = finalArr);
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length];
    return (arr3 = finalArr);
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      // 파라미터 값으로 인한 오류 방지
      const currentPage =
        state.totalPages >= action.payload.page
          ? action.payload.page
          : state.totalPages;
      const data = state.allData.slice(
        (currentPage - 1) * action.payload.perPage,
        currentPage * action.payload.perPage
      );
      return {
        ...state,
        data,
        totalPages:
          state.allData.length &&
          Math.ceil(state.allData.length / action.payload.perPage),
        params: action.payload,
        sortIndex: getIndex(
          state.allData,
          data,
          state.sortIndex,
          action.payload
        ),
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allData: action.payload,
      };
    case FILTER_USER:
      let value = action.payload;
      let filteredData = [];
      if (value.length) {
        filteredData = state.allData
          .filter((item) => {
            let startsWithCondition =
              item.user_name.toLowerCase().startsWith(value.toLowerCase()) ||
              item.email.toLowerCase().startsWith(value.toLowerCase()) ||
              item.phone_number.toLowerCase().startsWith(value.toLowerCase()) ||
              item.login_id.toLowerCase().startsWith(value.toLowerCase());

            let includesCondition =
              item.user_name.toLowerCase().includes(value.toLowerCase()) ||
              item.email.toLowerCase().includes(value.toLowerCase()) ||
              item.phone_number.toLowerCase().includes(value.toLowerCase()) ||
              item.login_id.toLowerCase().includes(value.toLowerCase());

            if (startsWithCondition) {
              return startsWithCondition;
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition;
            } else return null;
          })
          .slice(state.params.page - 1, state.params.perPage);
        return { ...state, filteredData };
      } else {
        filteredData = state.data;
        return { ...state, filteredData };
      }
    default:
      return { ...state };
  }
};

export default userReducer;
