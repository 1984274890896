// Initialize Firebase
export const config = {
  apiKey: "AIzaSyA11E4tBvrtUELlhu8Xnc_Buc3GFhBMFxE",
  authDomain: "wellsporzer.firebaseapp.com",
  projectId: "wellsporzer",
  storageBucket: "wellsporzer.appspot.com",
  messagingSenderId: "854178381885",
  appId: "1:854178381885:web:527aef83ca8ad616706a54",
  measurementId: "G-8J1ML512FL",
};
