import React from "react";
import * as Icon from "react-feather";
import {
  Bell,
  Bookmark,
  CreditCard,
  Globe,
  Layers,
  Mail,
  Package,
  Percent,
  User,
} from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/",
    permissions: ["admin"],
  },
  {
    type: "groupHeader",
    groupTitle: "쇼핑몰",
  },
  {
    id: "categories",
    title: "Categories",
    type: "item",
    icon: <Bookmark size={20} />,
    navLink: "/pages/categories",
    permissions: ["admin"],
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    icon: <Package size={20} />,
    navLink: "/pages/products",
    permissions: ["admin"],
    parentOf: ["/pages/products/detail"],
  },
  {
    id: "orders",
    title: "Orders",
    type: "item",
    icon: <CreditCard size={20} />,
    navLink: "/pages/orders",
    permissions: ["admin"],
    parentOf: ["/pages/orders/detail"],
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <User size={20} />,
    navLink: "/pages/users",
    permissions: ["admin"],
    parentOf: ["/pages/users/detail"],
  },
  {
    type: "groupHeader",
    groupTitle: "사이트",
  },
  {
    id: "coupons",
    title: "Coupons",
    type: "item",
    icon: <Percent size={20} />,
    navLink: "/pages/coupons",
    permissions: ["admin"],
    parentOf: ["/pages/coupons/detail"],
  },
  {
    id: "banners",
    title: "Banners",
    type: "item",
    icon: <Layers size={20} />,
    navLink: "/pages/banners",
    permissions: ["admin"],
    parentOf: ["/pages/banners/detail"],
  },
  //  제휴업체 일단 숨기기
  // {
  //   id: "cooperators",
  //   title: "Cooperators",
  //   type: "item",
  //   icon: <Globe size={20} />,
  //   navLink: "/pages/cooperators",
  //   permissions: ["admin"],
  // },
  {
    id: "notice",
    title: "Notice",
    type: "item",
    icon: <Bell size={20} />,
    navLink: "/pages/notice",
    permissions: ["admin"],
    parentOf: ["/pages/notice/detail"],
  },
  {
    id: "qna",
    title: "Qna",
    type: "item",
    icon: <Mail size={20} />,
    navLink: "/pages/qna",
    permissions: ["admin"],
    parentOf: ["/pages/qna/detail"],
  },
];

export default navigationConfig;
