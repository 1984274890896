import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import dataList from "./data-list/";
import productReducer from "./products";
import categoryReducer from "./categories";
import orderReducer from "./orders";
import userReducer from "./users";
import cooperatorReducer from "./cooperators";
import bannerReducer from "./banners";
import couponReducer from "./coupons";
import noticeReducer from "./notice";
import qnaReducer from "./qna";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  categories: categoryReducer,
  products: productReducer,
  orders: orderReducer,
  users: userReducer,
  cooperators: cooperatorReducer,
  banners: bannerReducer,
  coupons: couponReducer,
  notices: noticeReducer,
  qna: qnaReducer,
});

export default rootReducer;
