export const GET_QNA = "categories/GET_QNA";
export const GET_ALL_QNA = "categories/GET_ALL_QNA";
export const FILTER_QNA = "categories/FILTER_QNA";

const QnACategories = [
  { value: "", label: "선택해주세요." },
  { value: "01", label: "배송지연/불만" },
  { value: "11", label: "컬리패스 (무료배송)" },
  { value: "02", label: "반품문의" },
  { value: "03", label: "A/S문의" },
  { value: "06", label: "환불문의" },
  { value: "07", label: "주문결제문의" },
  { value: "08", label: "회원정보문의" },
  { value: "04", label: "취소문의" },
  { value: "05", label: "교환문의" },
  { value: "09", label: "상품정보문의" },
  { value: "10", label: "기타문의" },
];

const initialState = {
  data: [],
  allData: [],
  params: null,
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
};

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex((i) => i.id === arr2[0].id) + 1;
    let endIndex = arr.findIndex((i) => i.id === arr2[arr2.length - 1].id) + 1;
    let finalArr = [startIndex, endIndex];
    return (arr3 = finalArr);
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length];
    return (arr3 = finalArr);
  }
};

const getCategoryLabel = (value) =>
  QnACategories.find((q) => q.value === value)?.label || "-";

const qnaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QNA:
      // 파라미터 값으로 인한 오류 방지
      const currentPage =
        state.totalPages >= action.payload.page
          ? action.payload.page
          : state.totalPages;
      const data = state.allData.slice(
        (currentPage - 1) * action.payload.perPage,
        currentPage * action.payload.perPage
      );
      return {
        ...state,
        data,
        totalPages:
          state.allData.length &&
          Math.ceil(state.allData.length / action.payload.perPage),
        params: action.payload,
        sortIndex: getIndex(
          state.allData,
          data,
          state.sortIndex,
          action.payload
        ),
      };
    case GET_ALL_QNA:
      return {
        ...state,
        allData: action.payload,
      };
    case FILTER_QNA:
      let value = action.payload;
      let filteredData = [];
      if (value.length) {
        filteredData = state.allData
          .filter((item) => {
            let startsWithCondition =
              item.title.toLowerCase().startsWith(value.toLowerCase()) ||
              item.created_by.user_name
                .toLowerCase()
                .startsWith(value.toLowerCase()) ||
              getCategoryLabel(item.category)
                .toLowerCase()
                .startsWith(value.toLowerCase());

            let includesCondition =
              item.title.toLowerCase().includes(value.toLowerCase()) ||
              item.created_by.user_name
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              getCategoryLabel(item.category)
                .toLowerCase()
                .includes(value.toLowerCase());

            if (startsWithCondition) {
              return startsWithCondition;
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition;
            } else return null;
          })
          .slice(state.params.page - 1, state.params.perPage);
        return { ...state, filteredData };
      } else {
        filteredData = state.data;
        return { ...state, filteredData };
      }
    default:
      return { ...state };
  }
};

export default qnaReducer;
